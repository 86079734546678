import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
// import { Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import things_logo from '../../assets/images/articles/mobileiot/things-logo.png'
import mercedes_logos from '../../assets/images/projects/service/mercedes/mercedes-logos.png'
import adobelogo from '../../assets/images/projects/service/mercedes/adobe_logo.png'
import pic02 from '../../assets/images/projects/15.png'
import { useRef } from 'react'



const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
}

// class ServiceDesignM1 extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       renderSlider: false,
//     }
//   }

//   componentDidMount() {
//     this.setState({
//       renderSlider: true,
//     })
//   }

//   render() {
//     const props = this.props

    const ServiceDesignM1 = props => {

      const outcomeDiv = useRef(null);
    
      const scrolLWithUseRef = () => outcomeDiv.current?.scrollIntoView({ block: "start", behavior: "smooth" });

    return (
      <Layout>
        <Helmet>
          <title>Service Design - Electric car charging service</title>
          <meta name="Service Design - Mercedes" content="Service Design" />
        </Helmet>

        <div id="main" className="alt">
          <section id="work-banner">
            <div>
              <span className="image main">
                <Img
                  fluid={props.data.mercedescover.childImageSharp.fluid}
                  alt=""
                />
              </span>
            </div>
          </section>

          <div className="project-wrapper">
            <div className="project-content">
              <header>
              <p className="maisontags">Research, Service Design, User Experience, Digital UX/UI, Brand Identity</p>
                {/* <span className="tag">SERVICE DESIGN</span>
                <span className="tag">USER EXPERIENCE</span>
                <span className="tag">INTERACTION DESIGN</span>
                <span className="tag">DIGITAL UX</span>
                <span className="tag">DIGITAL UI</span> */}

                {/* <h1>From uncertainty to confidence: how we simplified 
                  EV mobility through a better parking and charging experience.
                </h1> */}

                <h1>
                  From uncertainty to confidence: how we simplified 
                  EV mobility through a better parking and charging experience.
                </h1>
                <h3>
                We developed a comprehensive service for Mercedes Smart Electric car owners, allowing them to easily find, book, track, and pay for parking and charging sessions. 
                Additionally, we empowered car park operators with digital tools to efficiently manage their parking and charging facilities.
                </h3>
                <br />
                <h3>
                By providing easy and stress-free access to electric charging infrastructure, 
                we built trust and encouraged more people to confidently transition to EV mobility.
                </h3>
              </header>
              <br />
              <section className="header-post">
              <div className="headerpost">
              {/* <div className="headerpost-left">
                <p>
                  *This project was carried out while working at the design
                  agency, THINGS, in Milan.
                </p>
                <p>
                  <a href="/say-hello"><u>Get in touch</u></a> with me for more 
                  details related to this project; such as my
                  role, the problems i faced, the processes i 
                  used and how i addressed the challenges.
                </p>
              </div>
              <div className="headerpost-right">
                <div className="client-image">
                  <img className="span-4" src={things_logo} alt="things logo" />
                </div>
                <br />
                <p>
                  Things.is a design and innovation agency for the Internet of
                  Things. We create products and services with their own
                  experiential ecosystems outlined by multi-channel touch
                  points. We envision products and services in the IoT era
                  through a human-centred approach. www.things.is
                </p>
              </div>             */}
            </div>
          </section>

          <div className="process">           
          <div className="process-left">
                <h4>
                Context of the project
                </h4>
                <span className="credits-text">
                Mercedes' Smart division in Italy set a goal to transition to an electric-only brand starting in 2022. 
                </span>
                <br />
                <span className="credits-text">
                They envisioned a comprehensive charging ecosystem that would offer seamless end-to-end services for EV owners to park and charge their Mercedes-Benz and Smart Electric drive vehicles. 
                </span>
                <br />
                <span className="credits-text">
                Their primary business goal was to convert existing internal combustion engine customers to electric vehicles.
                </span>
                
          </div>
          <div className="process-right">
              <h4>
              Industry              
              </h4>
              <span className="credits-text">
              Smart Mobility
              </span>
              <br />
              <h4>
              My role
              </h4>
              <span className="credits-text">
              🔍 The Researcher, ✍️ the Service Experience Designer, and 📱the UX/UI Designer.
              <br />
              <br />
              I collaborated with Users, Mercedes-Benz Leadership team, Parking Operators, Front & Back-end Developers, and Service & Product Designer.
              </span>
              <br />
              <h4>Client</h4>
                  <div className="client-image">
                  <img className="span-5" src={mercedes_logos} alt="bsmart logo" />
              </div>
              </div>
            </div>


          {/* <div className="process">           
          <div className="process-left">
                <h4>
                Context of the project
                </h4>
                <p>
                Two services for the Electric Mobility of Mercedes-Benz 
                group were to be designed. The first service was for the 
                Smart group and the second for EQ Electric Intelligence.
                </p>
                <p>
                Currently, Smart car owners use a mobile app called Ready 
                to Park, for finding parking in urban areas. We had to fully 
                redesign the existing experience of this service and also 
                add new features that would allow owners of Smart electric
                 drive vehicles to charge their cars. 
                </p>
          </div>
          <div className="process-right">
                <h4>
                Project overview
                </h4>
                <span className="credits-text">
                  <strong>PROJECT TYPE:</strong> Designing a physical-digital mobility 
                  experience that helps owners of electric cars to charge their vehicles.
                </span>
                <br />
                <span className="credits-text">
                  <strong>TEAM MEMBERS:</strong> Design team of
                  THINGS, Milan
                </span>
                <br />
                <span className="credits-text">
                  <strong>MY ROLE:</strong> Conducting service safaris, mapping current & future 
                  user journey, user flows of the mobile app, wireframing, planning & conducting 
                  usability testing, carrying contextual interviews, preparing questionnaires, 
                  analysing test results, UI design, prototyping on Adobe XD.
                </span>
                <div className="challenge-client">
                <br />
                <h5>CLIENT</h5>
                  <div className="client-image">
                    <img className="span-5" src={mercedes_logos} alt="bsmart logo" />
                  </div>
                </div>
            </div>

            </div> */}



              <section className="challenge-section">

                {/* <span className="project-step-number">01</span>
                <h2>the challenge</h2> */}

                {/* <div className="challenge">
                  <div className="challenge-desc">
                    
                    <h4>1 - Smart group</h4>
                    <p>
                    Smart have officially announced that it will 
                    become an electric-only brand, starting from 
                    the end of 2019. 
                    </p>
                    <p>
                    Our brief was to design a charging ecosystem that 
                    would provide a seamless end-to-end service for 
                    users to charge their Smart electric drive cars. 
                    </p>
                    <p>
                    This will help in-turn increase the conversion rate 
                    of current internal combustion engine customers into 
                    electric drive. 
                    </p>
                  </div>
                  
                </div> */}
                <div className="image">
                  <Img fluid={props.data.m2challenge.childImageSharp.fluid} />
                </div>

                <div className="image">
                  <Img fluid={props.data.m1challenge.childImageSharp.fluid} />
                </div>

                <div className="process"> 
              <div className="process-left">
              <p className="half-textlarge">
              First we understood EVs & Italian parking regulations, along with their constraints. 
              </p>
              </div>

              <div className="process-right">
              <p>
              We consulted with technical teams and subject matter experts to gain insights into EVs, including their batteries, performance, and specific nuances.   
              </p>
              <p>
              We also examined various types of parking and charging facilities, both on-street and off-street, as well as the rules, regulations, and stakeholders involved in providing these services.
              </p>               
              </div>
              </div>

                {/* <div className="process">           
            <div className="process-left">
              <p className="half-textlarge">
              Our challenge was to evolve and extend the existing 
              Ready to Park service experience by providing charging 
              features within it. 
              </p>
              </div>
              <div className="process-right">
              <h4>2 - EQ (Electric Intelligence)</h4>
              <br />
              <p>
              With the introduction of first Mercedes-Benz Plug-In Hybrid 
              Electric Vehicles (PHEV) and Battery Electric Vehicles (BEV), 
              the creation of a wide portfolio of EQ services was necessary.   
              </p>
              <p>
              Our service was intended to be available only to PHEV/BEV vehicles owners 
              with the aim to provide customers a complete solution for charging their 
              PHEV/BEV Mercedes-Benz.   
              </p>
                            
              </div>
            </div> */}

            <div className="project-outcome">
                <span className="project-outcome-link" onClick={scrolLWithUseRef}>
              see project outcomes >>
                </span>
              </div>

            </section>


              <section className="process-section">
                {/* <span className="project-step-number">02</span>
                <h2>the approach</h2> */}
                
                
                {/* <div className="process-image">
                  <Img 
                    fluid={props.data.mercedes_process.childImageSharp.fluid}
                  />
                </div> */}

              {/* <p className="full-textlarge">
              In order to get a clear understanding of how the existing 
              service worked, we went out onto the streets with the 
              Smart Electric car and simulated various use-cases with 
              the existing Ready to Park app.  
              </p> */}

                <div className="changebg">
                <div className="image">
                  <Img fluid={props.data.m1process53.childImageSharp.fluid} />
                </div>

              <div className="process"> 
              <div className="process-left">
              <p>
              Experiencing firsthand the process of driving and finding parking and charging in the city was crucial for understanding the current experience and its nuances.
              </p>
              </div>
              </div>

                <div className="image">
                  <Img fluid={props.data.m1process3.childImageSharp.fluid} />
                </div>

              <div className="process"> 
              <div className="process-left">
              <p className="half-textlarge">
              Parking is one of the most difficult and dysfunctional parts of Italian city life.
              </p>
              </div>

              <div className="process-right">
              
              <h4>We simulated various scenarios:</h4>
              <p>
              → Finding spots near destinations<br />
              → Using charging spots on & off-street<br />
              → Plugging car into a charging station<br />
              → Paying by SMS, meter, or smart card<br />
              </p>
              <p>
              We also used various apps that we discovered during benchmarking study.
              </p> 
              <p>
              Simultaneously, we interviewed both electric and non-electric car owners to understand how they 
              plan their journeys, make decisions about where to park and charge, and identify pain points 
              in their overall experience of using EVs in urban areas.
              </p>              
              </div>

              <div className="image">
                  <Img fluid={props.data.m1process4.childImageSharp.fluid} />
              </div>

              </div>

              <div className="process"> 
              <div className="process-left">
              <p>
              We discovered that most car journeys into the city involve daily parking challenges
              </p>
              </div>
              </div>

              <div className="process"> 
             
              <div className="process-left">
              <p className="half-textlarge">
              Uncertainty about spot availability, time wasted searching, and inefficient payment options. 
              </p>
              </div>
              <div className="process-right">
              <p className="half-textlarge">
              Inconvenience of full car parks and confusion about pricing and facility restrictions.
              </p>
              </div>
              </div>

              
               
                {/* <div id="project-images-wrapper">
                  <Slider className="image-scroll" {...settings}>
                    <Img fluid={props.data.m1process2.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process4.childImageSharp.fluid} />
                  </Slider>
                </div> */}

              {/* <div className="process">           
              <div className="process-right">
              <p className="half-textlarge">
              We also observed and interviewed parking operators 
              of these facilities to understand their current work-flow.  
              </p>
              <p>
              Knowing how  the back-office management dashboard, Quisque, 
              was used by parking operators to manage parking  allowed us 
              to understand how the service was delivered to the users. 
              And if there were any constraints, needs, and  pain points 
              in the delivery of service to the users.  
              </p>               
              </div>
              </div> */}

              </div>
              <section className="fullspan">

              <div className="image">
                  <Img fluid={props.data.m1process55.childImageSharp.fluid} />
              </div>
              </section>

              <div className="process"> 
              <div className="process-left">
              <p>
              We returned to observe and speak with parking operators to understand their current workflow.  
              </p>
              </div>
              <div className="process-right">
              <p>
              We examined their use of digital or offline tools for managing facilities and identified 
              their constraints, needs, and pain points in service delivery.
              </p>
              </div>
              </div>

              <section className="fullspan">
              <div className="image">
                  <Img fluid={props.data.m1process5.childImageSharp.fluid} />
              </div>
              </section>

              

              



              {/* <div id="project-images-wrapper">
                  <Slider className="image-scroll" {...settings}>
                    <Img fluid={props.data.m1process5.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process6.childImageSharp.fluid} />
                  </Slider>
              </div> */}

              {/* <div className="changebg">
              <div className="process">
              <div className="process-left">
              <h4>
              Integration with the Quisque back-office system
              </h4>
              </div>           
              <div className="process-right">
              <p>
              The service designed was integrated with the existing Quisque back-office dashboard.    
              </p>
              <p>
              Quisque is an enterprise parking management system designed to provide the 
              most relevant information in the least obtrusive fashion.     
              </p>               
              </div>
              </div>

              <p className="full-textlarge">
              The back office experience was also taken into consideration when designing the service 
              so as to ensure effective service provision by parking & charging facility operators to the users.  
              </p>

              <div className="image">
                  <Img fluid={props.data.m1process10.childImageSharp.fluid} />
              </div>
              </div> */}

              <div className="process">
              <div className="process-left">
              <p className="half-textlarge">
              I noticed differing ideas during a team discussion, so I drew user flow diagrams & paper wireframes to align our thinking.  
              </p>               
              </div>           
             
              <div className="process-right">
              <p>
              We discovered a major service constraint: parking operators and charging providers had different rules for reserving services.
              </p>
              <p>
              Parking spots could be reserved for 60 minutes, while charging stations could only be reserved for 30 minutes.   
              </p>
              <p>
              We needed to simplify our design around this constraint so that users could easily understand it and its impacts.   
              </p> 
              <p>
              These and other complexities were considered to map out an end-to-end service journey for EV users and parking and charging operators.
              </p>              
              </div>
              </div>

              <div className="bluebg">
              <p className="full-textlargebluebg">
              EV drivers needed clear information about facility details, costs, rules, and penalties before making a booking. 
              They should be able to easily find and reserve parking spots at locations close to their destination. <br /><br />
              Facility operators require ease and flexibility in setting up their facilities according to their needs, 
              along with insights to improve their operations.
              </p>
              </div>

              

              <section className="fullspan">
              <div className="image">
                  <Img fluid={props.data.m1process7.childImageSharp.fluid} />
              </div>
              </section>

              <div className="process">
              <p>
              Mapping the future service journeys revealed the various touchpoints that needed to be orchestrated to deliver a seamless experience: 
              </p>
              <div className="process-left">
              <ul>
                  <li>
                  Human — EV car owners, parking and charging operators
                  </li>
                  <br />
                  <li>
                  Physical - Electric car, charging cables, RFID charging card, charging stations
                  </li>
                </ul>
              </div> 

              <div className="process-right">
              <ul>
                  <li>
                  Digital - Mobile app, back-office management system
                  </li>
                  <br />                 
                  <li>
                  Space - Manual and automatic parking facilities, on-street charging stations
                  </li>                  
                </ul>               
              </div>
              </div>

              <section className="fullspan">
              <div className="image">
                  <Img fluid={props.data.m1process12.childImageSharp.fluid} />
              </div>
              </section>

              <div className="process">
                <p>
                  Wireframes were designed for key user flows, with the intention of testing, learning and de-risking. 
                </p>
                <div className="process-left">
                <h4>Hero flows</h4>  
                    <p>
                    Finding a charging spot <br /> 
                    Booking & driving to spot <br /> 
                    Tracking & ending session <br /> 
                    Session payment 
                    </p> 

                    
                </div>
                <div className='process-right'>
          
                    <h4>Alternative flows</h4>  
                    <p>
                    Auto-booking charging spot
                    Booking expired before arrival<br /> 
                    </p> 

                    <h4>Error flows</h4>  
                    <p>
                    Booked spot occupied by others<br />
                    Failed online payment
                    </p> 
                  
                </div>
                </div>

              

              
               
                {/* <div id="project-images-wrapper">
                  <Slider className="image-scroll" {...settings}>
                    <Img fluid={props.data.m1process7.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process8.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process9.childImageSharp.fluid} />
                  </Slider>
                </div> */}

              {/* <div className="bluebg">
              <p className="full-textlargebluebg">
              Mapping out the end-to-end user journey allowed us to visualise all 
              current steps and gain insights on the critical points of the existing 
              Ready to Park service.  
              </p>
              <p className="full-textlargebluebg">
              From this we identified key areas of the user experience we needed to 
              evolve & re-design in order to integrate the new charging features.  
              </p>
              </div> */}

              {/* <div className="process">           
              <div className="process-right">
              <p>
              The existing experience of Ready to Park was evolved 
              into a Ready to Park & Charge for 2 main contexts:
              </p>
              <p>
              Charging of electric car at an automatic & manually 
              operated garages (off-street).
              </p>
              <p>
              Charging of electric car at on-street charging stations.
              </p>
              </div>
              </div>

              <p className="full-textlarge">
              Critical points identified  for evolving & re-designing the service were:
              <br />
              <br />
              1) Searching on-street & off-street charging stations.
              <br />
              <br />
              2) Booking charging stations for a period of time.
              <br />
              <br />
              3) Physical & digital interaction with charging stations to start, track & end sessions.  
              </p> */}


              <div className="changebg">
              <div className="image">
                  <Img fluid={props.data.m1process56.childImageSharp.fluid} />
              </div>
              

              {/* <div className="process">           
              <div className="process-left">
              <h4>
              User experience and interaction design of mobile app
              </h4>
              </div>
              <div className="process-right">
              <p>
              Mapping the user journeys allowed us the identify the various touchpoints in this service.   
              </p>
              <ol>
                  <li>
                  Human - parking operators
                  </li>
                  <br />
                  <li>
                  Physical - electric car, charging cables, RFID charging card, charging stations
                  </li>
                  <br />
                  <li>
                  Space - manual and automatic parking garages, on-street charging stations
                  </li>
                  <br />
                  <li>
                  Digital - mobile app
                  </li>
                </ol>               
              </div>
              </div> */}

              

              
              {/* <p className="full-textlarge">
              Sessions with key clients, business, operations, and engineering stakeholders revealed another constraint in off-street facilities. 
              </p> */}

              
              
              <div className="image">
                  <Img fluid={props.data.m1process20.childImageSharp.fluid} />
              </div>

              <div className="process">           
              <div className="process-left">
              <p>
              Since we were working with parking and charging facilities 
              that were underground, there was unreliable or a lack of 
              wireless network connectivity.
              </p> 
              </div>
              <div className="process-right">
              <p>
              Bluetooth connectivity was used instead to remotely open gates 
              to the parking facilities, to unlock/lock the hatchet of charging 
              stations and to start/end charging sessions. 
              </p>               
              </div>
            </div>

              {/* <div className="process"> 
              <div className="process-left">
              <p>
              Since we were working with parking and charging facilities 
              that were underground, there was unreliable or a lack of 
              wireless network connectivity.
              </p>
              </div>          
              <div className="process-left">
              <p>
              Bluetooth connectivity was used instead to remotely open gates 
              to the parking facilities, to unlock/lock the hatchet of charging
               stations and to start/end charging sessions.
               </p>         
              </div>
              </div> */}

              

              {/* <div className="image">
                  <Img fluid={props.data.m1process11new.childImageSharp.fluid} />
              </div> */}

              

              {/* <div id="project-images-wrapper">
                  <Slider className="image-scroll" {...settings}>
                    <Img fluid={props.data.m1process20.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process21.childImageSharp.fluid} />
                  </Slider>
              </div> */}
              </div>

              <div className="image">
                  <Img fluid={props.data.m1process58.childImageSharp.fluid} />
              </div>

              <div className="process">           
              
              
              </div>

              <div id="project-images-wrapper">
                  <Slider className="image-scroll" {...settings}>
                    <Img fluid={props.data.m1process23.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process24.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process25.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process16.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process51.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process47.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process59.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process60.childImageSharp.fluid} />
                  </Slider>
              </div>

              <div className="image">
                  <Img fluid={props.data.m1process26.childImageSharp.fluid} />
              </div>

              <div className="process"> 
              <div className="process-left">
              <p className="half-textlarge">
              The transition between digital and offline experience was disjointed and confusing.
              </p>
              <p>
              We addressed this by integrating physical space elements into the mobile app.
              </p>
              <p>
               Additionally, the booked charging station to blink when the user’s smartphone is nearby, making it easier to identify and park the car accordingly.
               </p>
              </div>          
              <div className="process-right">
              <p className="half-textlarge">
              We also had to better design the experience when user's faced problems with their charging. 
              </p>
              <p>
              For example, we included illustrations to guide users to the correct charging station and help them start, track, and end their sessions. 
               </p>  
               <p>
               We also provided step-by-step instructions to assist users in interacting with the physical infrastructure to start and end their charging sessions.  
              </p>            
              </div>
              </div>

              <section className="fullspan">
              <div className="image">
                  <Img fluid={props.data.m1process61.childImageSharp.fluid} />
              </div>
              </section>

             

              <section className="fullspan">
              <div className="image">
                  <Img fluid={props.data.m1process65.childImageSharp.fluid} />
              </div>
              </section>

              <p>
              We encountered pushback from the engineering team. I walked them through the user journey based on our research and testing. After considering the user’s perspective, they realised the reason for the design decision.
              </p>

              




              

              {/* <p className="full-textlarge">
              Clear icons to show the different status of the parking and charging facilities. 
              <br />
              <br />
              Visually representing the timer in a way that shows the booked period of the charging stations at a glance.
              <br />
              <br />
              Illustrations that guide users to finding the right charging station and start, track & end session.   
              </p>


              <div id="project-images-wrapper">
                  <Slider className="image-scroll" {...settings}>
                    <Img fluid={props.data.m1process13_1.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process13_2.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process13_3.childImageSharp.fluid} />
                  </Slider>
              </div> */}


              {/* <div className="changebg">
              <div className="process">           
              <div className="process-left">
              <h4>
              User validation & app usability testing
              </h4>
              </div>
              <div className="process-right">
              <p>
              Once the new Ready to Park & Charge app was developed, 
              we planned for testing and validation with users and parking operators.    
              </p>              
              </div>
              </div>

              <p className="full-textlarge">
              The goal was to identify breaking points of the experience, 
              why they were so and define insights to fix them.    
              </p>

              <div className="process">           
              
              <div className="process-right">
              <p>
              Test cards were made to organise our research.     
              </p>  
              <p>
              why we are doing this test? 
              <br />
              what do we really want to know/learn from this test?
              <br />
              what action could we take if we knew that number or rate?
              <br />
              what assumptions are to be validated?     
              </p>
              <div className="image">
                  <Img fluid={props.data.m1process22.childImageSharp.fluid} />
              </div>            
              </div>
              </div>

              <p className="full-textlarge">
              Our testing focused on key moments - booking, driving to facility, 
              arrival & car handover, charging of car and payment.   
              <br />
              <br />
              Certain KPIs were defined so that we could measure metrics, analyse 
              & learn from it. We also defined behaviours to observe and the key 
              gains, pains and tasks we wanted to test.   
              </p>

              

              <div className="process">           
              <div className="process-left">
              <p className="half-textlarge">
              The testing was carried out in the city of 
              Rome, Italy;  4 scenarios and 3 variables 
              were tested.
              </p>
              </div>
              <div className="process-right">
              <p>
              Different research methods and tools were used:    
              </p> 
              <ol>
                  <li>
                  Observing participants using the service.
                  </li>
                  <br />
                  <li>
                  Interviewing users and parking operators.
                  </li>
                  <br />
                  <li>
                  Evaluating user’s mobile phone usage through a mobile screen & voice recording app.
                  </li>
                  <br />
                  <li>
                  Questionnaires for users & parking operators to rate the key steps of the experience.
                  </li>
                </ol>              
              </div>
              </div>

              
              </div> */}


              
              {/* <div className="process">           
              <div className="process-left">
              <h4>
              Consolidation of app usability based on user testing & feedback
              </h4>
              </div>
              <div className="process-right">
              <p>
              After the testing session, the results and feedback were 
              reviewed to identify the critical problem areas that had 
              to be addressed.     
              </p> 
              <p>
              Insights were drawn and improvement were made to the 
              user experience of the mobile app and other aspects of 
              the service.    
              </p>              
              </div>
              </div> */}

              

             


              {/* <div className="changebg">  
              <div className="process">           
              <div className="process-left">
              <h4>
              Designing a watch interface for seamless service experience
              </h4>
              </div>
              <div className="process-right">
              <p>
              We also decided to allow users who have an Apple Watch to 
              carry out main actions using their watch. For each phase of 
              the experience we analysed the main interactions between the 
              user and mobile app, and these were transferred to the watch 
              interface.      
              </p>                        
              </div>
              </div>

              <p className="full-textlarge">
              Key watch interactions such as tracking the countdown timer of 
              booking while on-route to the facility, and opening the gate/bar 
              of the parking facility made the service user-friendly; considering 
              that the user is behind the wheel in these interaction contexts.   
              </p>

              <div className="image">
                  <Img fluid={props.data.m1process28.childImageSharp.fluid} />
              </div>
              </div> */}



              <div className="changebg"> 
              <div className="image">
                  <Img fluid={props.data.m1process62.childImageSharp.fluid} />
              </div>
              <div className="image">
                  <Img fluid={props.data.m1process63.childImageSharp.fluid} />
              </div>

              <div className="process"> 
              <div className="process-left">
              <p className="half-textlarge">
              The back-office was to designed to be an operational and analytical dashboard.
              </p>  
              </div>        
              <div className="process-right">
              <p>
              We wanted to empower parking operators to effectively manage their offerings and service delivery while making a profit.
              </p>
              <p>
              A hierarchy system depending on their roles, skills, level of visibility and control in the back-office system was created.
              </p>  
              <p>
              For each stakeholder we understood their access restrictions, jobs-to-do, goals and needs
              </p>         
              </div>
              </div> 

              <div className="image">
                  <Img fluid={props.data.m1process64.childImageSharp.fluid} />
              </div>



              

              <div className="process"> 

              </div>
              </div>





              {/* <div className="changebg">    
              <div className="process">           
              <div className="process-left">
              <h4>
              EQ Charge mobile app - Visual design
              </h4>
              </div>
              <div className="process-right">
              <p>
              The EQ Charge service worked in a similar way to the one of the Ready to Park 
              & Charge. Once the Ready to Park & Charge app was completely designed, 
              the visual language of the EQ service was made.      
              </p>                        
              </div>
              </div>

              <p className="full-textlarge">
              We came up with options for colour scheme, buttons, key interactive elements, 
              micro animations & illustrations that reflected the brand identity of Mercedes-Benz EQ.   
              </p>

              <div id="project-images-wrapper">
                  <Slider className="image-scroll" {...settings}>
                    <Img fluid={props.data.m1process45.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process46.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process47.childImageSharp.fluid} />
                  </Slider>
              </div>

              <div className="process">           
              <div className="process-left">
              <h4>
              Testing and consolidation of the visual language of the EQ Charge mobile app 
              </h4>
              </div>
              <div className="process-right">
              <p className="half-textlarge">
              Key elements on screens were tested to evaluate how well the 
              users understood and perceived these visual elements.   
              </p>
              <p>
              Based on the feedback, we consolidated the visual design of the mobile app.      
              </p>                        
              </div>
              </div>
              

              <div id="project-images-wrapper">
                  <Slider className="image-scroll" {...settings}>
                    <Img fluid={props.data.m1process48.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process49.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process50.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process51.childImageSharp.fluid} />
                    <Img fluid={props.data.m1process52.childImageSharp.fluid} />
                  </Slider>
              </div>
              </div> */}
                         


              </section>



              <section id="outcome-section" ref={outcomeDiv} className="outcome-section">
                <div className="outcome-text-wrapper">

              <section className="fullspan">
              <div className="image">
                <Img fluid={props.data.m1outcome56.childImageSharp.fluid} />
              </div>
              

              </section>


                  {/* <span className="project-step-number">03</span>
                  <h2>the outcome</h2> */}

                  <h3>
                  We designed two innovative mobility services, Ready to Park &
                   Charge and Mercedes EQ Charge, for EV owners, transforming how
                    users interact with charging infrastructure. 
                  </h3>
                  <div className="outcome">
                    <div className="outcome-left">
                      <p>
                      Through a mobile app, users can now search and book parking and charging spots near their destinations in advance. 
                      <br />
                      <br />
                      Upon arrival, they are seamlessly guided to plug in and start their charging session, with real-time 
                      monitoring of their vehicle’s charge status and make payments with a single tap.
                      </p>
                    </div>
                    <div className="outcome-right">
                      <p>
                      We also developed a white-label web app for parking facility owners to track occupancy, monitor charging station status, and generate usage reports. 
                      <br />
                      <br />
                      By providing a customizable interface, parking facility owners can brand the platform according to their 
                      business requirements and improve their operational efficiency.
                      </p>
                    </div>
                    <div className="image">
                    <Img fluid={props.data.m1outcome53.childImageSharp.fluid} />
                    </div>
                    <div className="outcome">
                      <h3>
                      These efforts have significantly improved the accessibility and convenience of charging for Mercedes-Smart EV owners, making the process stress-free. This has not only enhanced the user experience but also reduced travel time to final destinations by 10-15 minutes.
                      </h3>
                      
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          

          <section className="fullspan">
          
            <div className="image">
              <Img fluid={props.data.m1outcome55.childImageSharp.fluid} />
            </div>
            
          </section>


          {/* remove these breaks below when the prototype code lines are un-commented */}

          
         

          


                  {/* commented the below prototype link section till the prototype is made */}

      {/* <div className="project-wrapper">
      <div className="project-content">
      <section className="process-section">
          <div className="changebg">
          <br />
          <br />
          <div className="process">           
            <div className="process-left">
              <h4>
              Prototype of the mobile app
              </h4>
              </div>
              <div className="process-right">
              <div>
              <img className="protologo" src={adobelogo} alt="" />
              </div>
              
              <div className="resume-download">
              <a href={"https://medium.com/@sagarsatam"} target="_blank" rel="noopener noreferrer" className="resume-download-link" download>
              See Prototype
              </a>
              </div> 
                             
              </div>
            </div>  
          </div>
    </section>
    </div>
    </div> */}


          <section className="fullspan">
           
            
            <br />
            <br />
           



            <div className="image">
              <Img fluid={props.data.m1outcome54.childImageSharp.fluid} />
            </div>
            <div className="image">
              <Img fluid={props.data.m1outcome57.childImageSharp.fluid} />
            </div>

          </section>


        <div className="project-wrapper">
          <div className="project-content">
          {/* <section className="process"> */}
          {/* <div className="changebg">

          
          <div className="process">           
            <div className="process-left">
              <h4>
              Problems faced during the project 
              and how they were solved
              </h4>
              </div>
              <div className="process-right">
              <p>
              A major service constraint (from the parking operators & energy 
              providers) was the different booking expiration time for parking 
              and charging services. This resulted in subsequent complexities 
              that had to be designed around. 
              </p>
             
              <p>
              We designed various interactions that enabled users to first clearly 
              understand these rules & its implications. Secondly the experience 
              we designed allowed them to take appropriate action easily. 
              </p>               
              </div>
            </div>
            

            <div className="process">           
              <div className="process-right">
              <p>
              Due to a mix of physical, spatial and digital actions that the user had to 
              perform there was a high probability of creating a disjointed experience, 
              if not designed properly.
              </p> 
              <p>
              We overcame this challenge by detailing out points in the experience 
              where this switch between physical-spatial-digital was occurring and 
              then designing elements that joined to create a seamless and cohesive 
              experience.
              </p>               
              </div>
            </div>

            <div className="process">           
              <div className="process-right">
              <p>
              Since we were working with parking and charging facilities 
              that were underground, there was unreliable or a lack of 
              wireless network connectivity.
              </p> 
              <p>
              Bluetooth connectivity was used instead to remotely open gates 
              to the parking facilities, to unlock/lock the hatchet of charging 
              stations and to start/end charging sessions. 
              </p>               
              </div>
            </div>

            <br />
            <br />
            <div className="process">           
            <div className="process-left">
                  <h4>
                  Reflection - what I learnt during the project & what I could have done better.
                  </h4>
            </div>
              <div className="process-right">
              <p>
              Due to premise of this project, our design team was not involved in the re-designing 
              of the back-office system, Quisque, that was used by the parking and charging operators.    
              </p> 
              <p>
              This meant that we were not able to improve the experience of the 
              parking operators, so that their work-flow can be in-line with the 
              newly re-designed app for the users. 
              </p>
              <p>
              This resulted in some unmet needs of the parking operators which 
              sometimes affected the quality of the service provided to the users, 
              when they reached the facility and interacted with the operators.
              </p> 
              <p>
              In order to deign a service holistically it is vital to have the 
              ability to improve the experience of all the key stakeholders.  
              </p>                
              </div>
            </div>
          </div> */}
          <br />
          <br />
          <br />
          
          
          <p className="full-text">
          Thank you & happy charging! <span role="img" aria-labelledby="mercedesemoji">⚡🚙</span> 
          </p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          
          <div className="full-text">
          <div className="hp-featured__hr-line" />
          <h5>NEXT PROJECT</h5>
          </div>
          <br />


          {/* </section> */}
        </div>
      </div>


      {/* try from featured page section */}
    {/* <div id="main"> */}
    <section id="two" className="tiles inner">
      {/* <div className="hp-featured__hr-line" />
        <h4>NEXT PROJECT</h4> */}
            <div className="tiles__wrapper">
              <a href="/work/service-design-tecne">
                <div className="tiles__content">
                  <div className="tiles__info">
                    {/* <div className="tiles__number">01</div> */}

                    {/* <h2 className="tiles__title">
                      electric car charging service.
                    </h2> */}

                    <div className="tiles__text">
                    Next gen combat training with an underglove fitted with
                    sensors & an app that gives precise feedback to improve.
                    </div>
                    <br />
                    <p className="maisontags-card">Research, UX Strategy, User Experience, Product Design, Digital UX/UI, Brand Identity</p>
                    {/* <div className="tiles__tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>USER EXPERIENCE</li>
                        <li>DIGITAL UX/UI</li>
                        <li>INTERACTION DESIGN</li>
                      </ul>
                    </div> */}
                  </div>
                </div>
                <div className="tiles__image">
                  <img src={pic02} alt="" />
                </div>
              </a>
            </div>
      </section>

      </div>











          {/* <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to check out my
              <br />
              <Link to="/#one">featured work.</Link>
            </p>
          </section> */}
        {/* </div> */}
        {/* <div className="footer-top">
          <div className="inner">
            <h2>other projects.</h2>
            <div id="work">
              <div className="card-wrapper">
                <a href="/work/service-design-tecne">
                  <div className="image">
                    <Img
                      fluid={props.data.project1.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                  <div className="card-info">
                    <div className="title">tecne.</div>
                    <div className="text">
                      Next gen combat training with an underglove fitted with
                      sensors & an app that gives precise feedback to improve.
                    </div>
                    <div className="tags">
                      <ul>
                        <li>UX STRATEGY</li>
                        <li>INTERACTION DESIGN</li>
                        <li>DIGITAL UX/UI</li>
                        <li>PRODUCT DESIGN</li>
                        <li>BRANDING</li>
                      </ul>
                    </div>
                  </div>
                </a>
              </div>
              <div className="card-wrapper">
                <a href="/work/service-design-kin">
                  <div className="image">
                    <Img
                      fluid={props.data.project2.childImageSharp.fluid}
                      alt=""
                    />
                  </div>
                  <div className="card-info">
                    <div className="title">kin.</div>
                    <div className="text">
                      Conversational-based service for Type II Diabetes
                      management that leverages on trusted relationships.
                    </div>
                    <div className="tags">
                      <ul>
                        <li>SERVICE DESIGN</li>
                        <li>UX STRATEGY</li>
                        <li>USER EXPERIENCE</li>
                      </ul>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>


        


        </div> */}



      </Layout>
    )
  }


export default ServiceDesignM1

export const fluidM1Image = graphql`
  fragment fluidM1Image on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    mercedescover: file(
      relativePath: {
        eq: "projects/service/mercedes/cover/mercedes-cover-image.jpg"
      }
    ) {
      ...fluidM1Image
    }
    m1challenge: file(
      relativePath: { eq: "projects/service/mercedes/challenge/1.jpg" }
    ) {
      ...fluidM1Image
    }
    m2challenge: file(
      relativePath: { eq: "projects/service/mercedes/challenge/2.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process2: file(
      relativePath: { eq: "projects/service/mercedes/process/2.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process3: file(
      relativePath: { eq: "projects/service/mercedes/process/3.png" }
    ) {
      ...fluidM1Image
    }
    m1process4: file(
      relativePath: { eq: "projects/service/mercedes/process/4.png" }
    ) {
      ...fluidM1Image
    }
    m1process5: file(
      relativePath: { eq: "projects/service/mercedes/process/5.png" }
    ) {
      ...fluidM1Image
    }
    m1process6: file(
      relativePath: { eq: "projects/service/mercedes/process/6.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process7: file(
      relativePath: { eq: "projects/service/mercedes/process/7.png" }
    ) {
      ...fluidM1Image
    }
    m1process8: file(
      relativePath: { eq: "projects/service/mercedes/process/8.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process9: file(
      relativePath: { eq: "projects/service/mercedes/process/9.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process10: file(
      relativePath: { eq: "projects/service/mercedes/process/10.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process11new: file(
      relativePath: { eq: "projects/service/mercedes/process/11.png" }
    ) {
      ...fluidM1Image
    }
    m1process12: file(
      relativePath: { eq: "projects/service/mercedes/process/12.png" }
    ) {
      ...fluidM1Image
    }
    m1process13_1: file(
      relativePath: { eq: "projects/service/mercedes/process/13_1.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process13_2: file(
      relativePath: { eq: "projects/service/mercedes/process/13_2.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process13_3: file(
      relativePath: { eq: "projects/service/mercedes/process/13_3.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process14: file(
      relativePath: { eq: "projects/service/mercedes/process/14.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process15: file(
      relativePath: { eq: "projects/service/mercedes/process/15.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process16: file(
      relativePath: { eq: "projects/service/mercedes/process/16.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process17: file(
      relativePath: { eq: "projects/service/mercedes/process/17.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process18: file(
      relativePath: { eq: "projects/service/mercedes/process/18.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process19: file(
      relativePath: { eq: "projects/service/mercedes/process/19.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process20: file(
      relativePath: { eq: "projects/service/mercedes/process/20.png" }
    ) {
      ...fluidM1Image
    }
    m1process21: file(
      relativePath: { eq: "projects/service/mercedes/process/21.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process22: file(
      relativePath: { eq: "projects/service/mercedes/process/22.png" }
    ) {
      ...fluidM1Image
    }
    m1process23: file(
      relativePath: { eq: "projects/service/mercedes/process/23.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process24: file(
      relativePath: { eq: "projects/service/mercedes/process/24.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process25: file(
      relativePath: { eq: "projects/service/mercedes/process/25.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process26: file(
      relativePath: { eq: "projects/service/mercedes/process/26.png" }
    ) {
      ...fluidM1Image
    }
    m1process27: file(
      relativePath: { eq: "projects/service/mercedes/process/27.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process28: file(
      relativePath: { eq: "projects/service/mercedes/process/28.png" }
    ) {
      ...fluidM1Image
    }
    m1process29: file(
      relativePath: { eq: "projects/service/mercedes/process/29.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process30: file(
      relativePath: { eq: "projects/service/mercedes/process/30.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process31: file(
      relativePath: { eq: "projects/service/mercedes/process/31.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process32: file(
      relativePath: { eq: "projects/service/mercedes/process/32.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process33: file(
      relativePath: { eq: "projects/service/mercedes/process/33.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process34: file(
      relativePath: { eq: "projects/service/mercedes/process/34.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process35: file(
      relativePath: { eq: "projects/service/mercedes/process/35.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process36: file(
      relativePath: { eq: "projects/service/mercedes/process/36.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process37: file(
      relativePath: { eq: "projects/service/mercedes/process/37.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process38: file(
      relativePath: { eq: "projects/service/mercedes/process/38.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process39: file(
      relativePath: { eq: "projects/service/mercedes/process/39.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process40: file(
      relativePath: { eq: "projects/service/mercedes/process/40.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process41: file(
      relativePath: { eq: "projects/service/mercedes/process/41.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process42: file(
      relativePath: { eq: "projects/service/mercedes/process/42.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process43: file(
      relativePath: { eq: "projects/service/mercedes/process/43.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process44: file(
      relativePath: { eq: "projects/service/mercedes/process/44.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process45: file(
      relativePath: { eq: "projects/service/mercedes/process/45.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process46: file(
      relativePath: { eq: "projects/service/mercedes/process/46.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process47: file(
      relativePath: { eq: "projects/service/mercedes/process/47.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process48: file(
      relativePath: { eq: "projects/service/mercedes/process/48.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process49: file(
      relativePath: { eq: "projects/service/mercedes/process/49.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process50: file(
      relativePath: { eq: "projects/service/mercedes/process/50.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process51: file(
      relativePath: { eq: "projects/service/mercedes/process/51.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process52: file(
      relativePath: { eq: "projects/service/mercedes/process/52.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process53: file(
      relativePath: { eq: "projects/service/mercedes/process/53.png" }
    ) {
      ...fluidM1Image
    }
    m1process55: file(
      relativePath: { eq: "projects/service/mercedes/process/55.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process56: file(
      relativePath: { eq: "projects/service/mercedes/process/56.png" }
    ) {
      ...fluidM1Image
    }
    m1process58: file(
      relativePath: { eq: "projects/service/mercedes/process/58.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process59: file(
      relativePath: { eq: "projects/service/mercedes/process/59.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process60: file(
      relativePath: { eq: "projects/service/mercedes/process/60.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process61: file(
      relativePath: { eq: "projects/service/mercedes/process/61.jpg" }
    ) {
      ...fluidM1Image
    }
    m1process62: file(
      relativePath: { eq: "projects/service/mercedes/process/62.png" }
    ) {
      ...fluidM1Image
    }
    m1process63: file(
      relativePath: { eq: "projects/service/mercedes/process/63.png" }
    ) {
      ...fluidM1Image
    } 
    m1process64: file(
      relativePath: { eq: "projects/service/mercedes/process/64.png" }
    ) {
      ...fluidM1Image
    } 
    m1process65: file(
      relativePath: { eq: "projects/service/mercedes/process/65.png" }
    ) {
      ...fluidM1Image
    }        
    m1outcome55: file(
      relativePath: { eq: "projects/service/mercedes/outcome/55.png" }
    ) {
      ...fluidM1Image
    }
    m1outcome53: file(
      relativePath: { eq: "projects/service/mercedes/outcome/53.png" }
    ) {
      ...fluidM1Image
    }
    m1outcome54: file(
      relativePath: { eq: "projects/service/mercedes/outcome/54.png" }
    ) {
      ...fluidM1Image
    }
    m1outcome56: file(
      relativePath: { eq: "projects/service/mercedes/outcome/56.jpg" }
    ) {
      ...fluidM1Image
    }
    m1outcome57: file(
      relativePath: { eq: "projects/service/mercedes/outcome/57.jpg" }
    ) {
      ...fluidM1Image
    }
    m1outcome58: file(
      relativePath: { eq: "projects/service/mercedes/outcome/58.png" }
    ) {
      ...fluidM1Image
    }
    mercedes_process: file(
      relativePath: { eq: "projects/process/mercedes-process.png" }
    ) {
      ...fluidM1Image
    }
    things_logo: file(
      relativePath: { eq: "articles/mobileiot/things-logo.png" }
    ) {
      ...fluidM1Image
    }
    eq_logo: file(
      relativePath: { eq: "projects/service/mercedes/eq-logo.png" }
    ) {
      ...fluidM1Image
    }
    mercedes_logos: file(
      relativePath: { eq: "projects/service/mercedes/mercedes-logos.png" }
    ) {
      ...fluidM1Image
    }
    smart_logo: file(
      relativePath: { eq: "projects/service/mercedes/smart-logo.png" }
    ) {
      ...fluidM1Image
    }
    project1: file(relativePath: { eq: "projects/15.png" }) {
      ...fluidM1Image
    }
    project2: file(relativePath: { eq: "projects/6.png" }) {
      ...fluidM1Image
    }
  }
`
